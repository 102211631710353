<template>
  <page-content>
    <edit-field-header
      :title="title"
      @routerBack="$router.back()"
    />

    <edit-field
      :fields="fields"
      :row-data="row_data"
      post-api="/role/AddRole"
      finish-router="role"
      permission="Role_Create"
    >
      <template #facility="scope">
        <slot-facility :company-id="scope.rowData.companyID" :facility-id="scope.rowData.facilityID" :row-data="scope.rowData" :is-edit="scope.isEdit" />
      </template>

      <template #module="scope">
        <slot-module :row-data="scope.rowData" :facility-id="scope.rowData.facilityID" />
      </template>
    </edit-field>
  </page-content>
</template>

<script>
import fields, { reloadFieldData } from "@/views/role/fields";
import EditField from "@/components/EditField";
import EditFieldHeader from "@/components/EditFieldHeader";
import PageContent from "@/components/PageContent";
import SlotFacility from "@/views/role/SlotFacility";
import SlotModule from "@/views/role/SlotModule";
import common from "@/common";


export default {
  name: "Add",
  components: {
    SlotModule,
    SlotFacility,
    PageContent,
    EditFieldHeader,
    EditField,
    fields
  },
  data() {
    return {
      title: common.getMenuName('role') + ' - ' + common.getI18n('common.add'),
      fields,
      // 如果是checkbox，里面写 field: [], 如ids: []
      row_data: {
        permissionIDs: [],
        companyID: common.getCompanyId(),
        facilityID: common.getFacilityId()
      }
    }
  },
  created() {
    reloadFieldData()
  },
}
</script>

<style scoped>

</style>
